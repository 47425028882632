<template>
  <div>
    <v-app-bar app color="#f6f6f6" elevate-on-scroll elevation="4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <NotificationBadgeDashboard />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-chip link wid>
              <v-badge dot bottom offset-y="10" offset-x="10" color="green">
                <v-avatar size="40">
                  <v-img src="@/assets/users.png" />
                </v-avatar>
              </v-badge>
              <span>{{ datosUser.nombre_completo }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img width="50" src="@/assets/users.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ datosUser.nombre_completo }}
              </v-list-item-title>
              <v-list-item-subtitle> En Linea </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="{ icon, title } in menus"
            :key="icon"
            link
            @click="handleMenuItemClick(title)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                class="ma-0 pl-2"
                v-model="switch1"
                flat
                :label="switch1 ? 'Online' : 'Offline'"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- SIDEBAR -->
    <v-navigation-drawer v-model="drawer" app>
      <v-img class="pa-4" src="@/assets/brico.png">
        <div class="text-center mt-4"></div>
      </v-img>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group v-model="group">
          <div v-for="{ icono, menu, ruta } in links" :key="icono">
            <v-list-item
              v-if="ruta"
              link
              @click="$router.push(ruta).catch((err) => {})"
            >
              <v-list-item-icon>
                <v-icon>{{ icono }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ menu }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <DialogCambioPass
      :isPasswordDialog="isPasswordDialog"
      @cerrar-cambio-pass="(estado) => (isPasswordDialog = estado)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogCambioPass from "../dialogs/DialogCambioPass.vue";

import NotificationBadgeDashboard from "./NotificationBadgeDashboard.vue";

export default {
  name: "Header",
  created() {
    this.createMenu();
    localStorage.setItem("xml_stat", true);
  },
  components: {
    DialogCambioPass,
    NotificationBadgeDashboard,
  },
  data() {
    return {
      switch1: true,
      isPasswordDialog: false,
      drawer: null,
      links: [],
      group: null,

      menus: [
        { title: "Perfil", icon: "mdi-account" },
        { title: "Cambiar Contraseña", icon: "mdi-key" },
        { title: "Cerrar Caja", icon: "mdi-cash-register" },
        { title: "Salir", icon: "mdi-logout" },
      ],
    };
  },

  methods: {
    handleMenuItemClick(title) {
      switch (title) {
        case "Salir":
          this.logout();
          break;
        case "Cambiar Contraseña":
          this.isCambioPass();
          break;
        case "Cerrar Caja":
          this.isCerrarCaja();
          break;
        default:
          // Acción por defecto si no coincide con "Salir" o "Cambiar Contraseña"
          break;
      }
    },
    isCambioPass() {
      this.isPasswordDialog = true;
      console.log(this.isPasswordDialog);
    },
    isCerrarCaja() {
      let id_caja = JSON.parse(localStorage.getItem("id_caja"));

      this.$router.push(`/pos/caja/cierre/${id_caja}`);
    },
    logout() {
      this.$store.dispatch("logout");
    },

    createMenu() {
      this.links = this.datosUser.menu;
    },
  },

  computed: {
    ...mapGetters({
      DatosUsuario: "datosUser",
    }),

    datosUser: {
      get() {
        return this.DatosUsuario;
      },
      set(newName) {
        return newName;
      },
    },
  },
  watch: {
    switch1(val) {
      localStorage.setItem("xml_stat", val);
    },
  },
};
</script>

<style></style>
